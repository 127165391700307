import React, { useState } from 'react';
import { Route, Link, Redirect, Switch } from 'react-router-dom';

import Login from './login';
import NotFound from './404';
import ConfigPage from './configPage';
import applyDeatail from './applyDeatail';

import './index.css'
import AddApply from './addApply';
import Home from './home';
import { storage } from '../utils/commonHelper';
import questionare from './questionare';

const Ant_main = () => {
    const [token,setToken] = useState(storage.get("token"))
    const [isLogin,setisLogin] = useState(token?true:false)
    // console.log(token);

    return (
        <div>
            {/* <Link to="/product/proda">A产品</Link><br />
            <Link to="/product/prodb">B产品</Link><br />
            <Link to="/product/prodc">C产品</Link><br /> */}
            <Switch>
                <Route exact path="/" render={() => {
                    if (isLogin) {
                        return <Home login={setisLogin}/>
                    } else {
                        return <Login login={setisLogin}/>
                    }
                }}></Route>
                {/* <Route path="/product/prodb" component={ProdB}></Route>
                <Route path="/product/prodc" component={ProdC}></Route>*/}
                <Route exact path="/config" component={ConfigPage}></Route>
                <Route exact path="/questionare" component={questionare}></Route>
                {/* <Route exact path="/add-apply" component={AddApply}></Route> */}
                <Route exact path="/detail/:id" component={applyDeatail}></Route>
                <Route exact path="/404" component={NotFound}></Route>
                <Redirect to="/404" />
            </Switch>
        </div>

    );
};

export default Ant_main;