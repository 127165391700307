import { Result, WhiteSpace, Button,List,Toast,Modal } from "antd-mobile";
import { storage } from "../utils/commonHelper";

const UserInfoComp = ({ userinfo,login}) => {
    const Item = List.Item;
    const alert = Modal.alert;
    const handleClick = ()=>{
        storage.remove("token");
        Toast.success("已退出登录",1)
        login(false);
        //window.location.reload();
    }
    const handleClear = ()=>{
        alert("提示","该操作将会清除本地所有账号相关信息,是否继续?",[
            {text:"取消"},
            {text:"确定",onPress:()=>{

                storage.remove("token");
                storage.remove("account");
                storage.remove("password");
                storage.remove("email");
                Toast.success("已清除所有数据")
                login(false);
        },style:{color:"red"}}])
    }
    const myImg = src => <img src={src} className="spe am-icon am-icon-md" alt="" />;
    const renderComp = () => {
        return <div><Result style={{ paddingTop: "21px" }}
            img={myImg(userinfo.headIcon)}
            title={ userinfo.userName+",欢迎您!" }
            message={""}
        />
            <List 
            //renderHeader={() => '基本信息'}  
            className="info-list">
                <Item extra={userinfo.majorName} >专业</Item>
                <Item extra={userinfo.departmentName} >学院</Item>
                <Item extra={userinfo.gender}>性别</Item>
                <Item extra={userinfo.className}>班级</Item>
                <Item extra={userinfo.admissionNumber}>学号</Item>
                <Item extra={userinfo.mobilePhone||"未知"}>手机号</Item>
            </List>
            <WhiteSpace />

            <Button onClick={handleClick}  style={{fontSize:"16px",margin:"1px 2px"}} type="warning" size="sm">退出登录</Button>
            <Button onClick={handleClear}  style={{fontSize:"16px",margin:"2px 2px"}} type="ghost" size="sm">清除数据并退出</Button>
        </div>
    }
    return <div className="userinfo">
        {userinfo && renderComp()}
    </div>
}
export default UserInfoComp