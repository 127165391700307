import { NavBar, Button, List, Toast, InputItem, DatePicker, Picker } from 'antd-mobile';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';

const ApplyDetail = (props) => {
    const info = props.location.info || {}
    const checkArr = [
        [
            {
                label: '是',
                value: true,
            },
            {
                label: '否',
                value: false,
            },
        ],
    ]
    const [date, setDate] = useState(info.time ? new Date(info.time) : '')
    const [users, setUsers] = useState([
        [
            {
                label: '老王',
                value: '1',
            },
            {
                label: '老李',
                value: '2',
            },
        ],
    ])
    const handleClick = () => {
  
        if (!isApply[0] && reason === '') {
            Toast.info("请输入不通过原因!")
            return
        }
        if (applyUser === '') {
            Toast.info("请选择审批人!")
            return
        }
        Toast.loading('提交中...', 1, () => {
            props.history.push("/")
        },true);
    }
    console.log(props);
    const [isApply, setIsApply] = useState([true])
    const [applyUser, setApplyUser] = useState('')
    const [reason, setReason] = useState('')
    return <div className='ApplyDetailWrapper pageWrapper'>
        <NavBar
            mode="dark"
            leftContent="Back"
            onLeftClick={() => props.history.goBack()}
        >审批详情</NavBar>
        <div className="content">
            <List >
                <InputItem
                    clear
                    disabled
                    value={info.reason}
                    style={{ textAlign: 'right' }}
                    placeholder="请输入原因"
                >原因</InputItem>
                <DatePicker
                    value={date}
                    disabled
                >
                    <List.Item arrow="horizontal">时间</List.Item>
                </DatePicker>

                <Picker
                    data={checkArr}
                    title="是否同意"
                    cascade={false}
                    value={isApply}
                    onOk={v => { setIsApply(v) }}
                >
                    <List.Item arrow="horizontal">是否同意</List.Item>
                </Picker>
                <Picker
                    data={users}
                    title="选择审批人"
                    cascade={false}
                    value={applyUser}
                    onOk={v => setApplyUser(v)}
                    onChange={v => setApplyUser(v)}
                >
                    <List.Item arrow="horizontal">审批人</List.Item>
                </Picker>
                {isApply[0] === false && <InputItem
                    clear
                    value={reason}
                    style={{ textAlign: 'right' }}
                    placeholder="请输入不通过原因"
                    onChange={(v) => { setReason(v) }}
                >不通过原因</InputItem>}
            </List>
            <Button type="primary" style={{ marginTop: 10 }} onClick={handleClick}>提交</Button>
        </div>
    </div>
}

export default withRouter(ApplyDetail)