import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd-mobile/dist/antd-mobile.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import md5 from  'js-md5';
import JSEncrypt from 'jsencrypt';
import NodeRsa from 'node-rsa';
import { constants } from 'crypto';

const publicKey = "-----BEGIN PUBLIC KEY-----\n      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDACwPDxYycdCiNeblZa9LjvDzb\n      iZU1vc9gKRcG/pGjZ/DJkI4HmoUE2r/o6SfB5az3s+H5JDzmOMVQ63hD7LZQGR4k\n      3iYWnCg3UpQZkZEtFtXBXsQHjKVJqCiEtK+gtxz4WnriDjf+e/CxJ7OD03e7sy5N\n      Y/akVmYNtghKZzz6jwIDAQAB\n      -----END PUBLIC KEY-----";
const privateKey = "-----BEGIN RSA PRIVATE KEY-----\nMIICXgIBAAKBgQC1D7qgYkvtWGyv0XJvnFZKUMJfUlzQUxr8YYvJFgSC/QBZaLkR\nGbBflnxtCIu/epE4jl8WlRGtMy4xTLgJ/46G0WXJBIRPcyQDnksgL1PKkBhWCCo7\nh0fUcRws63nFRA1g1GwC5RicWXae2tP4aI81WX5236FoJuJIXK2EGu+llQIDAQAB\nAoGBAJnNMKTImuKUeE6pReIVFR+vlkzOc9Tqo0O7wLghodd6/m70jDBAeBfahvs/\nhSY0AWPfXFqcahWMIjLyjmq/0Nq6OIHvhHXIrFNJOVl17m1KaJe+Nx/yiaZ3fJAl\nhZPaTYi7jpe6oiZU5AeSor719icQ7Zr0TtNqBXy14AF/stNBAkEA5f4olW+nyyYa\n3/RQ2875N6kQe3q7gKZBq9A2Evkw8qu9hkp5BxH4+RLgdtzWngocgiSbR9PXrkmi\nB8P3IZypaQJBAMmJGm7GfgslD6riaKdVA9oRUrTyPgw754s9fWE8c1HFPxbN/JE7\nKHsPNiIRJv5Nq72kZCkSS5Jx75uIfz58CU0CQQC3myoM+mEqxZXI2gM/J0E/lSFK\nYlWTw6ZDomm4xeS0pjQcJBHwegGX3eECIxTWf934a37nXZAeXDwVFPWSiL2ZAkEA\nkd3D3e2YiOR4JIzehk5IDWuAWysBscUh32vP9bLVHJgRA6ZvaYZR1E+8NIgJmwII\njagQPpHZVNNWQ1JWFpmb+QJAIbTkc1mKteish3zPu5IMZ4rn1+3QXCFoeDDkrZda\nuTgC637Hp863/GM5ebLPSZpl2RbqtgJtH8vUr+lYxu1qxA==\n-----END RSA PRIVATE KEY-----";
React.$md5 = md5;

React.$encrypt = new JSEncrypt();
React.$encrypt.setPublicKey(publicKey);


//RSA/None/NoPadding
let options = {
  encryptionScheme: {
    scheme:'pkcs1',
    padding: constants.RSA_NO_PADDING 
  }
}
let key = new NodeRsa(privateKey);
key.setOptions(options)

React.$decrypt = (data) => {
  return key.decrypt(data,'utf8')
}

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
