import { List,Badge } from 'antd-mobile';
import { render } from 'react-dom';

const ParseQusetion = ({data,answer})=>{
    const Item = List.Item;
    const Brief = Item.Brief;

    const Rendercontent = (a) =>{
        let s = "<div>";

        switch(a.wtlxdm)  {
            case 1: {
                for (let i = 0; i < a.wtda.length; i++) {
                    
                    let checked = a.wtda[i].dadm == answer ? "checked='true'" : '';
                    // let checked = true
                    s += "<div class='row col-12 radio-inline' style='margin-left:15px;'>"
                        + "<label class='radio'><input type='radio'" +checked+ "id='dadm_" + a.wtda[i].dadm + "' name='dadm_" + a.wtdm + "' value='" + a.wtda[i].dadm + "' class='my-radio rd'><label for='dadm_" + a.wtda[i].dadm + "' class='damc'>" + a.wtda[i].dabz + " " + a.wtda[i].danr + "  </label>"
                        + "</label></div>";
                }
            }; break;
            case 2: {
                for (let i = 0; i < a.wtda.length; i++) {
                    s += "<div class='row col-12 checkbox-inline' style='margin-left:5px;'>"
                           + "<label class='checkbox'><input type='checkbox' name='dadm_" + a.wtdm + "'  value='" + a.wtda[i].dadm + "' class='rd'><label class='damc'>" + a.wtda[i].dabz + " " + a.wtda[i].danr + "  </label>"
                           + "</label></div>";
                }
            }; break;
            case 3: {
                s += "<div>";
                for (let i = 0; i < a.wtda.length; i++) {

                    if (a.wtda[i].sfdhdm == 0) {
                        s += "<input type='text' class='form-control col-12 dadm_" + a.wtdm + "'  flag='" + a.wtda[i].dadm + "'  placeholder='" + a.wtda[i].danr + "' />";
                    }
                    else {
                        s += "<textarea  class='form-control  col-12  dadm_" + a.wtdm + "' flag='" + a.wtda[i].dadm + "'  placeholder='" + a.wtda[i].danr + "' rows='6' ></textarea>"
                    }
                }
                s += "</div>";

            }; break;
            case 4: {
                s += "<div class='row col-12'>";
                s += "<button class='col-12 btn btn-block btn-warning' onclick='dlwz(this)' flag='" + a.wtda[0].dadm + "' style='margin-top:5px;' >获取定位</button>"
                s += "<input type='text'  class='dadm_" + a.wtdm + " select-title col-12' flag='" + a.wtda[0].dadm + "'  id='dadm_" + a.wtda[0].dadm + "' style='margin-top:5px;' />"
                s += "</div>"
            }; break;
            case 5: {
                s += "<div>";
                for (let i = 0; i < a.wtda.length; i++) {
                    s += "<input type='text' class='dadm_" + a.wtdm + " select-title col-12'  id='dadm_" + a.wtda[i].dadm + "'  flag='" + a.wtda[i].dadm + "' />"
                }
               s+="</div>"
            }; break;

        }
        return s+"</div>"
    }
    // console.log(data);
    return <><Item wrap={true} multipleLine={true} extra={data.sfbddm && <Badge hot text={'必答题'} style={{ marginLeft: 12 }} />}>
        <div style={{fontSize:"16px"}}>{data.wtmc}</div>
        {data.wtms && <Brief style={{fontSize:"14px"}}>{data.wtms}</Brief>}
    </Item>
    
    <div style={{backgroundColor:'rgb(250, 250, 250)'}} dangerouslySetInnerHTML = {{__html:Rendercontent(data)}} ></div>
    </>
    
}
export default ParseQusetion;