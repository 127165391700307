import {cqutReq,apiReq,qdReq} from "./request";
import Qs from 'qs';
export const getLoginTicket = (account) => {
    return cqutReq.post("/common/login/getLoginTicket",{
        loginWay:"ACCOUNT",
        equipmentVersion:29,
        equipmentName:"xiaomi",
        equipmentId:getRamNumber(),
        appCode:"mobilePlatformApplicationCode",
        accountNumber:account
    },{isEncryption:true})
}
function getRamNumber(){
    var result='';
    for(var i=0;i<16;i++){
        result+=Math.floor(Math.random()*16).toString(16);//获取0-15并通过toString转16进制
    }
    return result
}
export const getUserinfo = () => {
    return cqutReq.post('/user/front/info')
}

export const getApplicationUrl = () => {
    return cqutReq.get('/officeHall/getApplicationUrl',{applicationCode:'4Pnw2624'})
}
export const login = (ticket,password) => {
    return cqutReq.post('/mobile/login/account/checkAccount/'+ticket, {password},{isEncryption:true})
}
export const getNotice = () =>{
    return apiReq.get("/getnotice")
}
export const uploadConfig = (email,admissionNumber,password,task_version,answer_sheet) =>{
    let data = {email,admissionNumber,password,task_version,answer_sheet}
    //console.log("uploadconfig",Qs.stringify(data));
    return apiReq.post("/upconfig",Qs.stringify(data))
}
export const userReport = (userInfo) =>{
    let data = "userName=" +encodeURIComponent(userInfo.userName)+ "&admissionNumber="+userInfo.admissionNumber+"&majorName="+encodeURIComponent(userInfo.majorName)+"&gender="+encodeURIComponent(userInfo.gender)+"&departmentName="+encodeURIComponent(userInfo.departmentName)+"&mobilePhone="+userInfo.mobilePhone;
    return apiReq.post("/report",data)
}
export const rmConfig = (admissionNumber) =>{
    return apiReq.post("/rmConfig","admissionNumber="+admissionNumber)
}
export const getUserConfig = (admissionNumber) =>{
    return apiReq.post(
    "/getconfig",
    "admissionNumber="+admissionNumber,
    {
        transformResponse:function(e){
            e = JSON.parse(e);
            if(e.code===200){
                e.data.answer_sheet = eval("JSON.parse('"+e.data?.answer_sheet+"')");
            }
            return e
        }
    }
    )}
export const getTask = ()=>{
    return qdReq.post("/home/get_mrrw")
}