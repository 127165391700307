// import './App.css';

import Ant_main from "./antd-mobile";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Ant_main />
      </header>

    </div>
  );
}

export default App;
