import axios from "axios";
import { getCommonParams,getSecretParam,Sign } from './cqutHelper'
import { storage } from './commonHelper'
import { decryptSecretParam } from "./cqutHelper";
import { GENDER_MAP } from "./commonMap"

//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const apiReq = axios.create({baseURL:"/cqut"});
export const qdReq = axios.create({baseURL:"/qd"});
export const cqutReq = axios.create({
    baseURL:"/ump",
    headers: {
        'Access-Control-Allow-Origin':'*',
        'Accept': 'application/json, text/plain, */*',
    },
})


cqutReq.interceptors.response.use(function(e){
    
    if( e.data.code==="40001"){
        switch(e.config.url){
            case '/user/front/info':
                e.data.content =decryptSecretParam(e.data)
                e.data.content.gender = GENDER_MAP[e.data.content.gender] || "女";
                break
            case '/officeHall/getApplicationUrl':
                //应用链接授权后cookie会返回.ASPAUTH，需要取出来
                if(e.data.content.redirectUrl){
                    let url = e.data.content.redirectUrl.replace("https://qd-yqcj.cqut.edu.cn","");
                    // console.log("应用链接:",url);
                    qdReq.get(url)
                }
                break
            default:
                break
        }
    }
    return e
})
cqutReq.interceptors.request.use(function(e){
    let W = !!e.isEncryption;
    let t = "/ump" + e.url +"?";
    let n = e.data || {};
    let n0 = getCommonParams();
    // console.log(e);
    n = Object.assign(n,n0)
    if (W){
        n0.secretParam = getSecretParam(n)
    }
    n0.sign = Sign(t,n0);
    e.data = null;
    if(e.applicationCode){
        n0.applicationCode = e.applicationCode
    }
    e.params = n0
    e.headers.token = storage.get("token") || ""
    // console.log("header",e.headers);
    return e
})
