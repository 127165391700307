export const STATUS_MAP = {
    "1": "审批中",
    "2": "审核不通过",
    "3": "审核通过"
}
export const TYPE_MAP = {
    "1": "事假",
    "2": "年假",
    "3": "病假"
}
export const GENDER_MAP = {
    "MAN": "男",
    "WOMAN": "女"
}