import { NavBar, Button, List, TextareaItem, InputItem, DatePicker, Picker } from 'antd-mobile';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
const AddApply = (props) => {
    const [date, setDate] = useState()
    const [users, setUsers] = useState([
        [
            {
                label: '老王',
                value: '1',
            },
            {
                label: '老李',
                value: '2',
            },
        ],
    ])
    const [applyUser, setApplyUser] = useState()
    return <div className='addApplyWrapper pageWrapper'>
        <NavBar
            mode="dark"
            leftContent="Back"
            onLeftClick={() => props.history.goBack() }
        >新建申请</NavBar>
        <div className="content">
            <List >
                <InputItem
                    clear
                    style={{ textAlign: 'right' }}
                    placeholder="请输入原因"
                >原因</InputItem>
                <DatePicker
                    value={date}
                    onChange={date => setDate(date)}
                >
                    <List.Item arrow="horizontal">时间</List.Item>
                </DatePicker>
                <Picker
                    data={users}
                    title="选择审批人"
                    cascade={false}
                    value={applyUser}
                    onChange={v => setApplyUser(v)}
                    onOk={v => setApplyUser(v)}
                >
                    <List.Item arrow="horizontal">审批人</List.Item>
                </Picker>
            </List>
            <Button type="primary" style={{ marginTop: 10 }}>提交</Button>
        </div>
    </div>
}

export default withRouter(AddApply)