import { Button, InputItem, WingBlank, WhiteSpace, Toast } from 'antd-mobile';
import React, { useState, useEffect } from 'react';
import { createForm } from 'rc-form'
import { withRouter } from 'react-router-dom';
import { login,getLoginTicket } from '../utils/cqut'
import { storage } from '../utils/commonHelper'

const Login = (props) => {
    const [email, setEmail] = useState(storage.get("email"))
    const [hasError, sethasError] = useState(false)
    const [loginname, setLoginname] = useState(storage.get("account"))
    const [password, setPassword] = useState(storage.get("password"))

    const onErrorClick = () => {
        if (hasError) {
            Toast.info('请输入正确的邮箱!');
        }
    }
    const onChange = (value) => {
        let reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        if (!reg.test(value)) {
            sethasError(true)
            // console.log(hasError, email);
        } else {
            sethasError(false)
        }
        setEmail(value)
    }


    const handleClick = () => {
        if (!loginname) {
            Toast.info("请输入学号!")
            return
        }
        if (!password) {
            Toast.info("请输入密码!")
            return
        }
        // if (!email) {
        //     Toast.info("请输入邮箱!")
        //     return
        // }
        Toast.loading('正在登录中...', 20);
        getLoginTicket(loginname).then(res =>{
            if(res.data.code === "40001"){
                let ticket= res.data.content.ticket;
                // console.log("ticket获取成功",ticket);
                if(ticket){
                    login(ticket,password).then(res => {
                        res = res.data;
                        // console.log(res.data);
                        if (res.code === "40001") {
                            Toast.success("登录成功",1)
                            storage.set("token", res.content.token);
                            storage.set("account", loginname);
                            storage.set("password", password);
                            storage.set("email", email);
                            props.login(true);
                        } else {
                            Toast.fail(res.message,2)
                        }
                    })
                }
            }else{
                if(res.code != 200){
                    Toast.offline("网络请求失败!",3)
                    return
                }
                Toast.fail(res.data?.message||"登录失败!",2)
            }
        }).catch(res =>{
            // console.log(res);
            Toast.offline("网络请求失败!",3)
        })


        //props.history.push("/apply")
    }
    return (

        <WingBlank >
            <WhiteSpace size='xl' />
            <WhiteSpace size='xl' />
            <WhiteSpace size='xl' />
            <h1 style={{ textAlign: "center" }}>CQUT疫情自动打卡登录</h1>
            <WhiteSpace size='xl' />
            <WhiteSpace size='xl' />
            <div style={{backgroundColor:"white",border:"1px solid white",borderRadius:"5px",width:"100%"}}>
            <InputItem clear placeholder="请输入学号" value={loginname} onChange={(value) => { setLoginname(value) }}>学号</InputItem>
            <InputItem clear type="password" placeholder="请输入密码" value={password} onChange={(value) => { setPassword(value) }}>密码</InputItem>
            <InputItem clear type="email"
                onErrorClick={onErrorClick}
                onChange={onChange}
                value={email} error={hasError} placeholder="请输入通知邮箱"
            >邮箱</InputItem>
            </div>
            <WhiteSpace size='lg' />
            <Button type="primary" onClick={() => {
                // props.login(true)
                handleClick()
            }}>登录</Button>
        </WingBlank>

    );
};
export default withRouter(Login);