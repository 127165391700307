
import { withRouter } from 'react-router-dom'
import ConfigPage from './configPage'
import UserInfoComp from './UserInfoComp'
import { TabBar, NavBar, Icon, WingBlank, Toast, NoticeBar } from 'antd-mobile'
import { useState,useEffect } from 'react'
import { getUserinfo, getNotice,userReport } from '../utils/cqut'
import { storage } from '../utils/commonHelper'

const Home = (props) => {
    const [userInfo, setUserInfo] = useState(null);
    const [notice, setnotice] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    useEffect(()=>{
        if (!notice) {
            //Toast.loading('正在获取公告...', 20);
            getNotice().then(res => {
                // console.log("获取的通知", res.data.data);
                if (res.data.data) {
                    setnotice(res.data.data)
                } else {
                    setnotice("暂无公告")
                }
            })
        }
    },[])
    useEffect(()=>{
        if (!userInfo) {
            Toast.loading('正在获取用户信息...');
            getUserinfo().then(res => {
                //console.log(res.data);
                if (res.data.code === "40001") {
                    let e = res.data.content
                    //decryptSecretParam(res.data);
                    // console.log("用户信息", e);
                    userReport(e);
                    setUserInfo(e);
                } else {
                    Toast.info(res.data.message);
                    storage.remove("token");
                    props.login(false);
                    //history.push('/')
                }
            }).catch(reason =>{
                Toast.fail("获取用户信息失败!");
                storage.remove("token");
                props.login(false);
            })
        }
    },[])

    const renderNavBar = () => {
        return <div><NavBar
            mode="dark"
            leftContent={<span style={{ marginTop: "-1px" }}>关闭</span>}
            icon={<Icon type="left" />}
            onLeftClick={() => props.login(false)}
            rightContent={
                <span onClick={() => window.location.reload()}>刷新</span>
            }
        //style={{ color: "#01CDCC" }}
        ></NavBar>
            <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7px' } }}>
                {notice}
            </NoticeBar>
        </div>
    }
    return <div className="homeWrapper">
        {renderNavBar()}
        <div style={{ position: 'fixed', height: 'calc(100% - 80px)', width: '100%', bottom: 0 }}>
            <TabBar
                unselectedTintColor="#949494"
                tintColor="#33A3F4"
                barTintColor="white"
                hidden={false}
            >
                <TabBar.Item
                    // title="我的审批"
                    key="audit"
                    icon={<div style={{
                        // display:'none',
                        width: '80px',
                        height: '14px',
                        fontSize: '14px',
                    }}>打卡配置</div>}
                    selectedIcon={<div style={{
                        width: '82px',
                        height: '14px',
                        fontSize: '14px',
                    }}>打卡配置</div>}
                    selected={activeTab === 0}
                    // badge={1}
                    onPress={() => {
                        setActiveTab(0)
                    }}>
                    <ConfigPage userinfo={userInfo}/>
                </TabBar.Item>

                <TabBar.Item
                    // title="我的申请"
                    key="apply"
                    icon={<div style={{
                        // display:'none',
                        width: '82px',
                        height: '14px',
                        fontSize: '14px',
                    }}>我的信息</div>}
                    selectedIcon={<div style={{
                        // display:'none',
                        width: '82px',
                        height: '14px',
                        fontSize: '14px'
                    }}>我的信息</div>}
                    selected={activeTab === 1}
                    // badge={2}
                    onPress={() => {
                        setActiveTab(1)
                    }}>
                    <UserInfoComp userinfo={userInfo} login={props.login}/>
                </TabBar.Item>
            </TabBar>
        </div>
    </div>
}
export default withRouter(Home)