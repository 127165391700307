import { NavBar,Icon, Button,Modal, Toast, WhiteSpace } from 'antd-mobile';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ParseQusetion from './parseQuestion';
import $ from 'jquery';
import { uploadConfig } from '../utils/cqut';
import { storage } from '../utils/commonHelper'
import { useEffect } from 'react';
const alert = Modal.alert;

const Questionare = (props)=>{
    // console.log(props);
    const [data,setData] = useState(props.location.taskData||null);
    const [userConfig,setUserConfig] = useState(props.location.userConfig||null);
    const [loginname, setLoginname] = useState(storage.get("account"))
    const [password, setPassword] = useState(storage.get("password"))
    useEffect(()=>{
        if(!data){
            props.history.push("/") 
        }
    },[data])
    // console.log(data);
    // console.log(userConfig);
    function getvalue(a,wtdm)
    {
        
        switch (a) {
            case 1: {
                let rdo = document.getElementsByName('dadm_' + wtdm);
                for (let i = 0; i < rdo.length; i++) {
                    if (rdo[i].checked === true) {
                        return rdo[i].value;
                        break;
                    } else {
                        if (i === rdo.length - 1) {
                            return;
                        }
                    }
                }
            }; break;
            case 2: {
                let checkbox = document.getElementsByName('dadm_' + wtdm);
                let obj = [];
                for (let i = 0; i < checkbox.length; i++) {
                    if (checkbox[i].checked === true) {
                        obj.push(checkbox[i].value);
                    }
                }
                if (obj.length > 0)
                    return obj;
                else return;
    
    
            }; break;
            case 3: {
                let text = document.getElementsByClassName("dadm_" + wtdm)
                let obj = [];
                for (let i = 0; i < text.length; i++) {
                    if (text[i].value !== null&&text[i].value.Trim().length>0) {
                        let s = { "dadm": text[i].attributes.getNamedItem("flag").value, "context": text[i].value.replace("'", " ").replace("\"", " ") }
                        obj.push(s);
                    }
                }
                if (obj.length > 0)
                    return obj;
                else return;
    
            }; break;
            case 4: {
                let text = document.getElementsByClassName("dadm_" + wtdm)
                let obj = [];
                for (let i = 0; i < text.length; i++) {
                    if (text[i].value !== null && text[i].value !== undefined && text[i].value !== "") {
                        try {
                            let s = { "dadm": text[i].attributes.getNamedItem("flag").value, "context": text[i].attributes.getNamedItem("context").value }
                            obj.push(s);
                        } catch (e) {
                            $("msg_context").text = "请授权获取地理位置信息";
                            $("#msg_model").model("show");
                            setTimeout(function () {
                                $("#msg_model").model("hide");
                            }, 3000);
                        }
                    }
                }
                if (obj.length > 0)
                    return obj;
                else return;
    
            }; break;
            case 5: {
                let text = document.getElementsByClassName("dadm_" + wtdm)
                let obj = [];
                for (let i = 0; i < text.length; i++) {
                    if (text[i].value !== null && text[i].value !== undefined && text[i].value !== "" && text[i].attributes.getNamedItem("sfwc") !== undefined && text[i].attributes.getNamedItem("sfwc").value == 1) {
                        let s = { "dadm": text[i].attributes.getNamedItem("flag").value, "context": text[i].value.replace("'", " ").replace("\"", " ") }
                        obj.push(s);
                    }
                }
                if (obj.length > 0)
                    return obj;
                else return;
    
            }; break;
            case 6: {
                let qxdm = document.getElementById("qxdm_" + wtdm)
                let xxdz = document.getElementById("xzqh_xxdz_" + wtdm);
                let obj = [];
                if (qxdm !== null && qxdm !== undefined && qxdm.value !== null && qxdm.value !== undefined && qxdm.value !== "") {
                    let s = { "dadm": qxdm.attributes.getNamedItem("flag").value, "context": qxdm.value }
                    obj.push(s);
                }
                if (xxdz !== null && xxdz !== undefined && xxdz.value !== null && xxdz.value !== undefined && xxdz.value !== "") {
                    let s = { "dadm": xxdz.attributes.getNamedItem("flag").value, "context": xxdz.value }
                    obj.push(s);
                }
                if (obj.length > 0)
                    return obj;
                else return;
    
            }; break;
        }
        return;
    }
    function getanswer(tm){
        if(!userConfig){
            return
        }
        let dtk = userConfig?.answer_sheet;
        let ans,type;
        switch (tm.wtlxdm) {
            case 1:
                type = '_danxuanti';
                break;
            case 2:
                type = '_duoxuanti';
                break;
            case 3:
                type = '_tiankongti';
                break;
            case 4:
                type = '_diliweizhiti';
                break;
            case 5:
                type = '_shijianti';
                break;    
            case 6:
                type = '_xingzhengquhuati';
                break;
            default:
                break;
        }

        for(let i=0;i<dtk[type].length;i++){
            if(dtk[type][i].key === tm.wtdm){
                ans = dtk[type][i].value;
                break
            }
        }
        // console.log("题目:"+ tm.wtmc + ",答案:"+ans);
        return ans
    } 
    function submit() {
        let list = data.rwwtlb;
        let dtk = {};//答题卡
        let danxuanti = [];
        let duoxuanti = [];
        let tiankongti = [];
        let shijianti = [];
        let diliweizhiti = [];
        let xingzhengquhuati = [];
    
        for (let i = 0; i < list.length; i++) {
            let obj = getvalue(list[i].wtlxdm, list[i].wtdm);
            if (list[i].sfbddm == 1) {
                if (obj == undefined || obj == null) {
                    alert("提示","题目：" + list[i].wtmc + " 并未完成")
                    return;
                }
                else
                {
                    switch (list[i].wtlxdm)
                    {
                        case 1: { danxuanti.push({ "key": list[i].wtdm, "value": obj }) }; break;
                        case 2: { duoxuanti.push({ "key": list[i].wtdm, "value": obj }) }; break;
                        case 3: { tiankongti.push({ "key": list[i].wtdm, "value": obj }) }; break;
                        case 4: { diliweizhiti.push({ "key": list[i].wtdm, "value": obj }) }; break;
                        case 5: { shijianti.push({ "key": list[i].wtdm, "value": obj }) }; break;
                        case 6: { xingzhengquhuati.push({ "key": list[i].wtdm, "value": obj }) }; break;
                    }
    
                }
            }
            else {
                if (obj !== undefined  && obj !== null) {
                    switch (list[i].wtlxdm) {
                        case 1:  danxuanti.push({ "key": list[i].wtdm, "value": obj }); break;
                        case 2:  duoxuanti.push({ "key": list[i].wtdm, "value": obj }); break;
                        case 3:  tiankongti.push({ "key": list[i].wtdm, "value": obj }); break;
                        case 4: diliweizhiti.push({ "key": list[i].wtdm, "value": obj });break;
                        case 5: shijianti.push({ "key": list[i].wtdm, "value": obj }); break;
                        case 6: xingzhengquhuati.push({ "key": list[i].wtdm, "value": obj });break;
                        default:
                            break
                    }
                }
            }
        }
    
        if (danxuanti !== undefined && danxuanti !== null) {
            dtk._danxuanti=danxuanti;
        }
        if (duoxuanti !== undefined && duoxuanti !== null) {
            dtk._duoxuanti=duoxuanti;
        }
        if (tiankongti !== undefined && tiankongti !== null) {
            dtk._tiankongti= tiankongti;
        }
        if (diliweizhiti !== undefined && diliweizhiti !== null) {
            dtk._diliweizhiti = diliweizhiti;
        }
        if (shijianti !== undefined && shijianti !== null) {
            dtk._shijianti = shijianti;
        }
        if (xingzhengquhuati !== undefined && xingzhengquhuati !== null) {
            dtk._xingzhengquhuati = xingzhengquhuati;
        }
        let dtk1 = JSON.stringify(JSON.stringify(dtk));
        dtk1=dtk1.substring(1,dtk1.length-1);
        //alert(this.rw.yqrwbb);
        return dtk1
    }
    const handleClick = ()=>{
        let dtk = submit();
        if(!dtk) return;
        Toast.loading("正在保存配置...");
        uploadConfig(props.location.email,loginname,password,data.yqrwbb,dtk).then(res=>{
            if(res.data.code === "200"){
                Toast.success(res.data.data,2,()=>{
                    props.history.push("/") 
                })
            }else{
                // console.log(res);
                Toast.fail("配置保存失败:"+res.data.data)
            }
        }).catch(r =>{
            Toast.offline("网络请求失败!")
        })
    }
    return <div>
        <NavBar
        mode="dark"
        leftContent={<span style={{marginTop:"-2px"}}>Back</span>}
        icon={<Icon type="left"/>}
        onLeftClick={() => {
            props.history.goBack();
        }}
        rightContent={
            <span onClick={() => {
                window.location.reload()
            }}>刷新</span>
        }
        >上报配置</NavBar>
        {/* <WhiteSpace size="sm" /> */}
        {data && data.rwwtlb.map((v,i) => {
                return <ParseQusetion  key={i} data={v} answer={userConfig ? getanswer(v):null} />
        })}
        {/* <WingBlank size="sm">
        </WingBlank> */}

        <WhiteSpace size="sm" />
        <Button type="primary" onClick={handleClick}>提交</Button>
        <WhiteSpace size="sm" />
    </div>
}
export default withRouter(Questionare)