import React from 'react'
const appSecret = "mobileplatform-010010001";
const getNonce = (e) => {
    return e > 21 ? null : (e = e || 14,
        parseInt((Math.random() + 1) * Math.pow(10, e - 1)))
}

export const getCommonParams = () => {
    return {
        appVersion:'V5.3.04',
        universityId: 100005,
        appKey: "mobileplatform",
        timestamp: new Date().getTime(),
        nonce: getNonce(),
        clientCategory: "ANDROID"
    }
}
export const decryptSecretParam = (n)=>{
    let e = "";
    n.content.secretUserInfo.forEach(function(t, n) {
        e += React.$decrypt(t)
    });
    // console.log(JSON.parse(e));
    return JSON.parse(e)
}

export const getSecretParam = (e) => {
    e = JSON.stringify(e)
    var t = [], n = 0;
    for (var o = 0; o <= e.length; o++){
        if((o + 1) % 30 === 0){

            t.push(encodeURI(React.$encrypt.encrypt(e.substring(n, o))));
            n = o;
        }
    }
    if(n !== e.length){
        t.push(encodeURI(React.$encrypt.encrypt(e.substring(n, e.length))));
    }
    return JSON.stringify(t)
}
export const Sign = (cgi,commonParams) => {
    let o = {};
    let r = cgi;
    Object.keys(commonParams).sort().forEach(function (key) {
        o[key] = commonParams[key];
    })
    for (var s in o){
        r += s;
        r += "=";
        r += o[s];
        r += "&";
    }
    r += "appSecret";
    r += "="; 
    r += appSecret;
    let c = React.$md5(r).toUpperCase();
    return c;
}
