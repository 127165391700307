import React from 'react';
import { STATUS_MAP } from '../utils/commonMap';
import { Steps } from 'antd-mobile';
import { withRouter } from 'react-router-dom';

const Step = Steps.Step;

const ApplyComponent = (props) => {
    //props type name time reason status process 
    return <div className="applyItemWrapper" onClick={() => { 
            props.history.push({
                pathname:"/detail/" + props.id,
                info:{
                    reason:props.reason,
                    time:props.time
                }
            })
        }}>
        <div className="header">
            <div className="left">
                <p style={{ margin: 0, fontSize: "16px" }}>打卡日志</p>
                <p>打卡时间：{props.time}</p>
            </div>
            <div className="right" style={props.status === "2" ? { color: "red" } : props.status === "3" ? { color: "green" } : {}}>
                {props.status !== '1' && STATUS_MAP[props.status]}
            </div>
        </div>
        <Steps size="small" current={props.process.length} >
            {props.process.map(v => {
                return <Step key={v.id} current={v.status} description={v.remark} status={v.status === '1' ? 'wait' : v.status === '2' ? "error" : 'finish'} title={
                    <div className="stepItem">
                        <span>
                            {v.name}
                        </span>
                        <span>
                            {STATUS_MAP[v.status]}
                        </span>
                    </div>
                } />
            })}
        </Steps>
    </div>
};

export default withRouter(ApplyComponent);