import { Button, Modal, Toast, Steps, WhiteSpace, List } from 'antd-mobile'
import ApplyComponent from './applycomponent'
import { useState } from 'react'
import { Prompt, withRouter } from 'react-router-dom'
import { getUserConfig, getApplicationUrl, getTask,rmConfig } from '../utils/cqut'
import { useEffect } from 'react'
import { storage } from '../utils/commonHelper'
const ConfigPage = ({ userinfo, history }) => {
    const prompt = Modal.prompt;
    const alert = Modal.alert;
    const Step = Steps.Step;
    const Brief = List.Item.Brief;
    const [email, setEmail] = useState(storage.get("email"))
    const [task, setTask] = useState(null);
    const [userConfig, setUserConfig] = useState(null);
    const [applyList, setApplyList] = useState([
        {
            name: '老王',
            time: '2020-01-01',
            status: '2',
            type: '1',
            reason: '脑壳痛',
            id: '1',
            currentApuser: '2',
            process: [
                {
                    id: '1',
                    name: '老陈',
                    status: '3',
                    remark: ''
                },
                {
                    id: '2',
                    name: '老李',
                    status: '2',
                    remark: '扯谎'
                }
            ]
        }
    ])
    let fail = 0;
    const DOgetTask = () => {
        getTask().then(res => {
            fail = 0;
            if (res.data == null || res.data == undefined || res.data == "" || res.data.rwwtlb.length <= 0) {
                Toast.info('今日没有任务!')
                setTask({ nodata: true })
                return
            }
            if (res.data) {
                Toast.success('每日任务获取成功', 2)
                setTask(res.data)
            } else {
                Toast.fail("获取每日任务失败!", 2)
            }
        }).catch(r => {
            fail++
            setTimeout(() => {
                if (fail > 3) {
                    window.location.reload();
                    return
                }
                DOgetTask()
            }, 1500)
            // setTask({nodata:true})
        })
    }
    useEffect(() => {
        if (userinfo && !userConfig) {
            Toast.loading('正在获取云端配置...', 20);
            setTimeout(() => {
                getUserConfig(userinfo.admissionNumber).then(res => {
                    if (res.data.code === 200) {
                        // console.log("用户配置",res.data.data);
                        Toast.success('云端配置获取成功', 2)
                        setUserConfig(res.data.data)
                    } else {
                        Toast.info(res.data.msg,2)
                    }
                })
            }, 800);
        }
    }, [userinfo])

    useEffect(() => {
        if (!task) {
            getApplicationUrl().then(res => {
                if (res.data.code === "40001") {
                    Toast.success("获取应用链接成功", 2)
                    DOgetTask();
                } else {
                    Toast.fail("获取应用链接失败,请联系作者或刷新重试!", 2)
                }
            })
        }
    }, [task])
    const steps = [{
        title: '基础信息',
        description: email ? '获取成功' : '通知邮箱为空',
        status: email ? 'finish' : 'error'
    }, {
        title: '问卷版本',
        description: userConfig ? task?.nodata ? "云端版本:" + userConfig.task_version : userConfig?.task_version === task?.yqrwbb ? "云端版本:" + userConfig.task_version : '问卷有更新,最新版本:' + task?.yqrwbb||"获取失败" : '未配置问卷',
        status: userConfig ? task?.nodata ? "finish" : userConfig?.task_version === task?.yqrwbb ? 'finish' : 'error' : 'process',
    }, {
        title: '配置状态',
        description: userConfig ? task?.nodata ? "已上传到云端" : userConfig?.task_version === task?.yqrwbb ? '已上传到云端' : '问卷已更新,请重新配置数据' : '未上传云端',
        status: userConfig ? task?.nodata ? "finish" : userConfig?.task_version === task?.yqrwbb ? "finish" : 'error' : 'process'
    }].map((s, i) => <Step key={i}
        title={<span onClick={() => { }}>{s.title}</span>}
        status={s.status}
        description={<span style={s.status === "finish" ? { color: "#07b12bfa" } : s.status === "error" ? { color: "red" } : { color: "#bbb" }}>{s.description}</span>}
    />);
    const inputEmail = ()=>{
        prompt('提示', '请输入用于接收打卡通知的邮箱', [
            { text: '取消' },
            { text: '确定', onPress: value =>{ 
                
                let reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
                if(reg.test(value)){
                    storage.set("email",value);
                    setEmail(value);
                    return true
                }
                Toast.fail("请输入正确的邮箱!",2)
                inputEmail()
                return false
            } },
        ], 'default', email)
    }
    const handleClick = () => {

        if (!email) {
            if(userConfig?.email){
                storage.set("email",userConfig.email);
                setEmail(userConfig.email);
            }else{
                inputEmail()
                return
            }
        }
        history.push({ pathname: '/questionare', taskData: task, email: email, userConfig: userConfig })
    }
    const handleClose = () =>{
        alert("警告","关闭打卡将会清除所有打卡相关配置数据,是否继续?",[
            {text:"取消"},
            {text:"确定关闭",onPress:()=>{
                Toast.loading("正在关闭打卡...")
                rmConfig(userConfig?.admissionNumber).then(res=>{
                    if(res.data.code===200){
                        Toast.success("自动打卡已关闭!")
                        window.location.reload()
                    }else{
                        Toast.fail("自动打卡关闭失败,原因:"+res.data.msg)
                    }
                }).catch((res)=>{
                    Toast.offline("请求失败!")
                })
        },style:{color:"red"}}])

    }
    return <div className="applyWrapper">
        {/* <div className="sub-title">配置状态</div>
        <WhiteSpace /> */}
        <WhiteSpace />
        <Steps current={userConfig ? task?.nodata ? 3 : userConfig?.task_version === task?.yqrwbb ? 3 : 2 : 1} direction="horizontal" size="small">{steps}</Steps>

        {<><WhiteSpace size="lg" /><Button disabled={task?.rwwtlb ? task?.nodata : true} onClick={handleClick} type={task?.nodata ? "warning" : "primary"} size="middle" >{task?.nodata ? "今日没有任务" : "配置问卷数据"}</Button></>}
        <WhiteSpace size="sm" />
        <Button onClick={handleClose} type="ghost">关闭打卡</Button>
        <WhiteSpace size="xl" />
        <div style={{marginTop:"10px"}}>
            <Brief style={{fontSize:"16px"}}>使用说明:</Brief>
            <div style={{height:"180px",fontSize:"13px",color:"red",lineHeight:"35px",border:"1px solid silver",margin:"8px 0",padding:"4px",borderRadius:"10px"}}>
                    1.本工具主要功能为自动打卡<br/>
                    2.初次使用请配置问卷数据<br/>
                    3.问卷数据保存后就行了<br/>
                    4.每日会将打卡结果发送至登录页面所设置的邮箱<br/>
                    5.问卷有更新时请到本页面重新配置问卷数据
            </div>
        </div>

        {/* //!userConfig||userConfig?.task_version !=  */}
        {/* <Button type="primary" onClick={() => history.push('/newapply')}>新建申请</Button> */}
        {/* <Button onClick={getApplicationUrl}>获取链接</Button> */}
        {/* 
        {applyList.map(v => {
            return <ApplyComponent key={v.id} {...v} />
        })} */}
    </div>

}
export default withRouter(ConfigPage)